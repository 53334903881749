import currency from "currency.js";
import { format, startOfMonth } from "date-fns";
import { useQuery } from "react-query";
import "../../assets/scss/dashboard.scss";
import { useAuth } from "../../hooks/useAuth";
import {
  ArchiveFillIcon,
  ArrowDownGrowthIcon,
  ArrowUpGrowthIcon,
  FileListIcon,
  LineChartIcon,
} from "../Icons";
import CustomerBalance from "./CustomerBalance";
import RevenueVsExpenses from "./RevenueVsExpenses";
import SalesOverView from "./SalesOverview";
import TopSellingProduct from "./TopSellingProduct";
import TopEnquireProduct from "./TopEnquireProduct";
import useDebounce, { useCurrencies, useIsGovernmentInvexERP } from "../../utils/hooks";
import { analyticsOptions, formatForQty } from "../../utils/helpers";
import queryString from "query-string";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useState, useEffect, } from "react";
import { Navigate } from "react-router-dom";
import { services } from "../../config";

export default function Dashboard() {
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const { prevailingCurrencySymbol } = useCurrencies();
  const { backendUrl } = useAuth();
  const initialFilterParams = {
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  //  console.log(currency(8000.0).distribute(12));


  const getStats = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/dashboard-stats?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        //  credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["DASHBOARD_STAT", debouncedqueryParams],
    () => getStats(),
    {}
  );

  // FOR HELLOENERGY
  if (services.frontendUrl.includes("helloenergy.invexerp.io")) {
    return <Navigate to="/warehouse/main-asset-register" />
  }

  return (
    <div>
      <div className="dashboard-content">
        {!isGovernmentInvexERP && (
          <div class="position-relative">
            <section className="stats">
              <div className="stat">
                <div className="icon">
                  <FileListIcon />
                </div>
                <div className="details">
                  <p>
                    {data?.totalSales
                      ? currency(data?.totalSales, {
                        symbol: prevailingCurrencySymbol,
                        precision: 2,
                        format: formatForQty,
                      }).format()
                      : "..."}
                  </p>
                  <span>Total Sales</span>
                </div>
              </div>

              <div className="stat">
                <div className="icon icon-1">
                  <ArrowUpGrowthIcon />
                </div>
                <div className="details" title={data?.totalSalesFigures}>
                  <p>
                    {data?.totalSalesFigures
                      ? currency(data?.totalSalesFigures, {
                        symbol: prevailingCurrencySymbol,
                      }).format()
                      : "..."}
                  </p>
                  <span>Total Sales</span>
                </div>
              </div>

              <div className="stat">
                {/*  <div className="icon icon-2">
              <ArrowDownGrowthIcon />
            </div> */}
                <div className="icon icon-1">
                  <ArrowUpGrowthIcon />
                </div>
                <div className="details" title={data?.totalProfit}>
                  <p>
                    {data?.totalProfit
                      ? currency(data?.totalProfit, {
                        symbol: prevailingCurrencySymbol,
                        precision: 2,
                      }).format()
                      : "..."}
                  </p>
                  <span>Total Profit</span>
                </div>
              </div>

              {/*  <div className="stat">
              <div className="icon icon-3">
                <LineChartIcon />
              </div>
              <div className="details">
                <p>+0%</p>
                <span>Sales Growth</span>
              </div>
            </div> */}
              <div className="stat">
                <div className="icon icon-4">
                  <ArchiveFillIcon />
                </div>
                <div className="details">
                  <p>
                    {data?.itemOutofStock
                      ? currency(data?.itemOutofStock, {
                        symbol: "",
                        precision: 2,
                        format: formatForQty,
                      }).format()
                      : "..."}
                  </p>
                  <span>Item out of Stock</span>
                </div>
              </div>
            </section>
            <div class="position-absolute top-0 end-0 p-2 stat-control">
              <div className="position-relative z-10">
                <Select
                  classNamePrefix={`form-select-analytics`}
                  isSearchable={false}
                  options={analyticsOptions}
                  value={analyticsOptions.find(
                    (el) => el.value === queryParams.by
                  )}
                  onChange={(selected) => {
                    setQueryParams({
                      ...queryParams,
                      by: selected?.value,
                    });
                  }}
                />
                {queryParams.by === "select-year" && (
                  <div className="position-absolute -bottom-100 end-0 pt-2">
                    <Form.Control
                      type="number"
                      value={queryParams.year}
                      onChange={(e) =>
                        setQueryParams({
                          ...queryParams,
                          year: e.target.value,
                        })
                      }
                      autoFocus={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <section className="charts">
          <RevenueVsExpenses />
          <SalesOverView />
        </section>

        <section className="infos">
          <CustomerBalance />
          <TopSellingProduct />
        </section>
        {/* <section className="row">
          <div className="col-6 all-cards">
            <CustomerBalance />
          </div>
          <div className="col-3 all-cards">
            <TopSellingProduct />
          </div>
          <div className="col-3 all-cards">
            <TopEnquireProduct />
          </div>
        </section> */}
      </div>
    </div>
  );
}
